import stickybits from 'stickybits'
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

const swiper = new Swiper('.period-swiper', {
	modules: [Navigation],
	centeredSlides: true,
	slidesPerView: 'auto',
	spaceBetween: 48,
	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
	},
	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	// And if we need scrollbar
})

// Lightbox
const lightboxes = () => {
	const lightbox = document.querySelector('.lightbox')
	if (lightbox === null) return
	const lightboxImg = lightbox.querySelector('img')
	const lightboxBg = lightbox.querySelector('.lightbox-bg-overlay')
	const lightboxCaption = lightbox.querySelector('figcaption')
	const triggers = document.querySelectorAll('.lightbox-image')
	lightboxCaption.innerText = ''
	if (triggers.length > 0) {
		triggers.forEach((trigger) => {
			trigger.addEventListener('click', () => {
				const img = trigger.querySelector('img')
				const src = img.getAttribute('src')
				const cap = img.getAttribute('data-caption')

				lightboxImg.setAttribute('src', src)
				lightboxCaption.innerText = cap
				lightbox.classList.add('open')
			})
		})
	}
	lightboxBg.addEventListener('click', () => {
		lightbox.classList.remove('open')
	})
}
lightboxes()

// Title Anchors

const urlify = (string) => {
	return string
		.replace(/[^a-zA-Z0-9 ]/g, '')
		.replace(/\s+/g, '-')
		.toLowerCase()
}

const titleAnchors = () => {
	const titles = document.querySelectorAll('h1, h2, h3, h4, h5, h6')
	if (titles === null) return
	titles.forEach((title) => {
		title.setAttribute('id', urlify(title.innerText))
	})
	const h2s = document.querySelectorAll('h2')
	h2s.forEach((h2) => {
		h2.setAttribute('class', 'toc-title')
	})
}
titleAnchors()

const copyToClipboard = (headerID) => {
	const text = `${window.location.href.split('#')[0]}#${headerID}`
	navigator.clipboard.writeText(text).then(
		function () {
			console.log('Async: Copying to clipboard was successful!', text)
		},
		function (err) {
			console.error('Async: Could not copy text: ', err)
		},
	)
}

const handleHeaderCopyLink = () => {
	document.querySelectorAll('h2').forEach((header) => {
		header.addEventListener('click', () => {
			copyToClipboard(header.getAttribute('id'))
		})
	})
}
handleHeaderCopyLink()

const handleFab = () => {
	const fab = document.querySelector('.sticky-fab')
	if (!fab) return
	stickybits(fab, { useStickyClasses: true, stickyBitStickyOffset: 500 })
}
handleFab()

// Back to top
const backToTop = () => {
	const btn = document.querySelector('.back-to-top')
	if (!btn) return
	btn.addEventListener('click', () => {
		window.scrollTo(0, 0)
	})
	window.addEventListener('scroll', () => {
		if (window.scrollY > 200) {
			btn.classList.add('visible')
		} else {
			btn.classList.remove('visible')
		}
	})
}
backToTop()

// Table of Contents
const handleTableOfContents = () => {
	const tocContainer = document.querySelector('.fab.toc')
	if (!tocContainer) return
	const sidebar = document.querySelector('.c12-table-of-contents')
	const inner = sidebar.querySelector('.inner')
	const innerList = inner.querySelector('ol')
	const trigger = tocContainer.querySelector('a')
	const gradientOverlay = document.querySelector('.sidebar-gradient')
	const closeBtn = sidebar.querySelector('.close')

	const tocTitles = document.querySelectorAll('.toc-title')
	const render = () => {
		const toNodes = (html) =>
			new DOMParser().parseFromString(html, 'text/html').body
				.childNodes[0]
		tocTitles.forEach((title) => {
			const node = toNodes(
				`<li class="title-anchor"><a href="#${title.getAttribute('id')}">${title.innerHTML}</a></li>`,
			)
			node.addEventListener('click', () =>
				document.body.classList.remove('sidebar-open'),
			)
			innerList.appendChild(node)
		})
	}
	render()

	trigger.addEventListener('click', (e) => {
		e.preventDefault()
		document.body.classList.add('sidebar-open')
	})
	gradientOverlay.addEventListener('click', () => {
		document.body.classList.remove('sidebar-open')
		// handleFixedBody()
	})
	closeBtn.addEventListener('click', () => {
		document.body.classList.remove('sidebar-open')
		// handleFixedBody()
	})
}
handleTableOfContents()

const menuBtn = document.querySelector('.menu-btn')
menuBtn &&
	menuBtn.addEventListener('click', () => {
		document.body.classList.toggle('menu-open')
		menuBtn.classList.toggle('is-active')
	})

const flexCarousels = document.querySelectorAll('.flex-carousel')
flexCarousels.forEach((carousel) => {
	const s = new Swiper(carousel, {
		modules: [Navigation],
		slidesPerView: 1,
		spaceBetween: 48,
		// If we need pagination
		// Navigation arrows
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		// And if we need scrollbar
	})
})
